<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a href="/" class="navbar-brand">Eliot</a>
  </nav>
</template>

<script>
export default {
  name: 'MainNav'
}
</script>
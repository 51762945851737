<template>
  <div>
    <div id="modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shadow">
          <div class="modal-header">
            <h5 class="h5 text-white mb-0">{{ this.title }}</h5>
            <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <!-- &times; -->
                <span class="bi bi-star-fill text-white"></span>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="this.pictureUrl" class="img-fluid" />
            <div v-if="this.description">
              <hr />
              {{ this.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-columns pt-3">
      <div class="card " v-for="post in posts" :key="post.id" @click="postClicked(post)">
        <img class="card-img-top" :src="post.picture.thumb.url">
        <div class="card-body d-flex align-items-center justify-content-center">
          <div>
            <h5 class="card-title text-center">{{ post.title }}</h5>
            <p>{{ post.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostsIndex',
  props: {
    posts: {
      type: Array,
      default: () => []
    },
  },
  data: function() {
    return {
      title: '',
      description: '',
      pictureUrl: '',
    }
  },
  methods: {
    postClicked: function(post) {
      console.log(post.category)
      this.title = post.title
      this.description = post.description
      this.pictureUrl = post.picture.url
      $('#modal').modal('show')
    }
  }
}
</script>
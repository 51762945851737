class StickyNav {
  constructor() { }

  stick(e) {
    var offset = $(e).position()
    $(document).on('scroll', () => {
      var scrollTop = $(document).scrollTop()
      if (scrollTop > offset.top) {
        $(e).find('.navbar').addClass('fixed-top')
      } else {
        $(e).find('.navbar').removeClass('fixed-top')
      }
    })
  }
}

module.exports = StickyNav

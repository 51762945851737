// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'


import 'bootstrap'
import icon from 'bootstrap-icons/font/bootstrap-icons'
import '../stylesheets/application'
import './components'
import StickyNav from './stickyNav'
import Scroller from './scroller'


Vue.use(TurbolinksAdapter)
var scroller = new Scroller()
var stickynav = new StickyNav()

document.addEventListener('turbolinks:load', () => {
  // $('[data-toggle="tooltip"]').tooltip()
  // $('[data-toggle="popover"]').popover()
  stickynav.stick('#main-nav')

  $('#scroll-me').on('click', function() {
    scroller.to(this, '#main-nav')
  })
  new Vue({
    el: '[data-behavior="vue"]',
  });
});
class Scroller {
  constructor() { }
  to(e, anchor) {
    $('html,body').animate(
      { scrollTop: $(anchor).offset().top },
      'slow'
    )
  }
}

module.exports = Scroller

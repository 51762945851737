<template>
  <draggable :disabled="!enabled" :list="posts" class="card-columns" ghost-class="ghost" :move="checkMove" @start="dragging = true" @end="dragging = false" draggable=".card">
    <div v-for="post in posts" class="card" :key="post.id">
      <img class="card-img-top" :src="post.picture.url">
      <div class="card-body d-flex align-items-center justify-content-center">
        <h5 class="card-title text-center">{{ post.title }} - {{ post.id }}</h5>
      </div>
    </div>
  </draggable>
</template>

<script>
import Rails from "@rails/ujs"
import draggable from 'vuedraggable'
export default {
  name: 'PostsSort',
  components: { draggable },
  props: {
    original_posts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      enabled: true,
      posts: this.original_posts,
      dragging: false
    }
  },
  methods: {
    checkMove: function(e) {
      window.console.log(e.draggedContext)

      var data  = new FormData
      data.append('post[position]', e.draggedContext.futureIndex)

      // console.log(e.draggedContext.index, data.get('post[position]'))

      Rails.ajax({
        url: '/backend/posts/' + e.draggedContext.element.id + '/move.json',
        type: 'PATCH',
        data: data,
        datatype: 'json'
      })
    }
  },
  watch: {
    dragging(newValue) {
      if (newValue) {
        this.delayedDragging = true
        return
      }
      this.$nextTick(() => {
        this.delayedDragging = false
      })
    }
  }
}
</script>
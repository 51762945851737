<template>
  <div>
    <div class="card-columns">
      <div class="card" v-for="page in pages" :key="page.id" :on-click="page.id">
        <div class="card-body">
          <div>
            <h5>{{page.title}}</h5>
            <div id="content">{{page.content}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  props: {
    pages: {
      type: Array,
      default: () => []
    }
  }
}
</script>